import camelcase from "camelcase";
import { convert } from "cashify";
import cx from "classnames";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactElement, ReactNode, useEffect, useState } from "react";

import Testimonials from "components/Home/Testimonials";
import DefaultLayout from "components/Layouts/DefaultLayout";
import useUser from "lib/hooks/useUser";
import { domain, slack as slackConfiguration } from "lib/publicConf";
import createShiftPic from "public/home-page/create-a-shift.png";
import manageTeamsPic from "public/home-page/manage-teams.png";
import screenshotPic from "public/home-page/screenshot-browser.png";
import slackNotificationsPic from "public/home-page/slack-notifications.png";
import ArrowRightIcon from "svg/heroicons/arrow-right.svg";
import BeakerIcon from "svg/heroicons/beaker.svg";
import CheckIcon from "svg/heroicons/check.svg";
import ChevronDownIcon from "svg/heroicons/chevron-down.svg";
import ManChillingOutIllustration from "svg/illustrations/man-chilling-out.svg";
import TeamworkIllustration from "svg/illustrations/teamwork.svg";
import WomanChillingOutIllustration from "svg/illustrations/woman-chilling-out.svg";
import SlackLogoMark from "svg/slack-logo-mark.svg";

function HomePage() {
  const { user } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (router.pathname === "/" && user?.organization?.slug !== undefined) {
      router.push(`/app/${user.organization.slug}/dashboard`);
    }
  }, [router, user]);

  const title = "TurnShift - Automate Your Team Schedule";
  const description =
    "🙌  TurnShift is a Slack application to share recurring tasks, duties, and manage rotations within your teams.";
  const socialImage = `https://${domain}/social.png`;

  return (
    <>
      <Head>
        <title>{title}</title>
        <link href={`https://${domain}/`} rel="canonical" />
        <meta content={title} name="title" />
        <meta content={description} name="description" />
        <meta content="website" property="og:type" />
        <meta content={title} property="og:title" />
        <meta content={description} property="og:description" />
        <meta content={socialImage} property="og:image" />
        <meta content={`https://${domain}`} property="og:url" />
        <meta content="summary_large_image" property="twitter:card" />
        <meta content={slackConfiguration.appId} name="slack-app-id" />
      </Head>

      <div className="container mx-auto">
        <main className="xl:relative">
          <div className="relative pb-10 mx-auto w-full max-w-7xl">
            <div className="px-4 pt-8 lg:pt-24 md:w-3/5">
              <h1 className="text-5xl lg:text-6xl font-extrabold tracking-tight leading-tight text-center md:text-left">
                Automate your
                <br />
                <span className="text-indigo-600">team schedule</span>
              </h1>
              <p className="mx-auto mt-5 md:mt-16 max-w-md md:max-w-3xl text-lg sm:text-xl text-gray-600">
                TurnShift is a <b>Slack application</b> to share and schedule
                recurring tasks with your team. <br />
                <br />
                Spreadsheets are boring, internal tools are buggy. Use the right
                shift scheduling tool.
              </p>

              <div className="mt-12 sm:mt-16">
                {user?.organization?.slug ? (
                  <div className="sm:flex sm:justify-center md:justify-start space-y-2 sm:space-y-0 sm:space-x-2">
                    <div className="rounded-md border shadow">
                      <Link
                        href={
                          user.organization.slackBotToken === null
                            ? `/app/${user.organization.slug}/settings/slack`
                            : `/app/${user.organization.slug}/dashboard`
                        }
                      >
                        <a className="flex justify-center items-center py-3 md:py-4 px-8 md:px-6 w-full text-base md:text-lg font-medium leading-6 text-gray-600 hover:text-gray-600 bg-white rounded-md border border-transparent focus:border-indigo-300 focus:ring-indigo-500 transition duration-150 ease-in-out focus:outline-none">
                          <ArrowRightIcon className="mr-3 h-6" /> Your dashboard
                        </a>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="sm:flex sm:justify-center md:justify-start sm:items-center space-y-8 sm:space-y-0 sm:space-x-4">
                      <div className="rounded-md border shadow">
                        <a // eslint-disable-line @next/next/no-html-link-for-pages
                          className="group flex justify-center items-center py-2 md:py-3 px-8 md:px-10 w-full text-base md:text-lg leading-6 text-white hover:text-gray-900 bg-indigo-600 hover:bg-white rounded-md border border-transparent focus:border-indigo-700 focus:ring-indigo-500 transition duration-150 ease-in-out focus:outline-none"
                          href="/api/slack/directInstallRedirect?fromHomePage=yes"
                        >
                          <div className="p-2 mr-3 bg-white rounded-full">
                            <SlackLogoMark className="w-6 h-6" />
                          </div>{" "}
                          <div>
                            Add to <strong>Slack</strong>
                          </div>
                        </a>
                      </div>
                      <p className="sm:mt-0 text-lg text-center sm:text-left text-gray-600">
                        14-day free trial. <u>No credit card required</u>.
                      </p>
                    </div>

                    <p className="mx-auto mt-6 max-w-md md:max-w-3xl text-sm sm:text-base italic text-gray-500">
                      We respect your privacy: TurnShift can&apos;t read your
                      messages or list private channels.
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="hidden sm:block relative md:absolute md:inset-y-0 md:right-0 md:pt-10 mt-8 md:mt-20 w-full md:w-2/5 h-64 sm:h-72 md:h-full">
              <TeamworkIllustration alt="" className="w-full h-full" />
            </div>
          </div>
        </main>
      </div>

      <div className="mt-8 sm:mt-20 md:mt-48 text-4xl lg:text-5xl font-extrabold tracking-tight text-center text-gray-900">
        😍 The smart team <br className="sm:hidden" />
        calendar is here
      </div>

      <div className="mx-auto mt-4 xl:w-4/5" style={{ maxWidth: "2000px" }}>
        <Image
          alt="Screenshot of the TurnShift UI"
          height={1167}
          loading="eager"
          placeholder="blur"
          priority
          src={screenshotPic}
          width={1870}
        />
      </div>

      <div className="mt-8 sm:mt-20 text-4xl lg:text-5xl font-extrabold tracking-tight text-center text-gray-900">
        🗣 Customers
      </div>

      <div className="mt-8 sm:mt-16">
        <Testimonials />
      </div>

      <div className="pt-20 -mt-20" id="features"></div>

      <div className="md:block mt-8 sm:mt-16 md:mt-32 text-4xl lg:text-5xl font-extrabold tracking-tight text-center text-gray-900">
        Here&apos;s how <br className="sm:hidden" />
        TurnShift works 👇
      </div>

      <div className="container mx-auto mt-14 md:mt-44 max-w-7xl">
        <div className="md:flex items-center px-6 mx-auto">
          <div className="md:pr-24 pb-8 md:w-1/2">
            <h2 className="mx-auto max-w-lg md:max-w-none text-2xl sm:text-4xl font-extrabold tracking-tight leading-8 sm:leading-tight text-gray-900">
              Create Shift Schedules
            </h2>
            <p className="mx-auto mt-3 max-w-lg md:max-w-none text-lg leading-7 text-gray-600">
              Create one shift schedule per need. If you want to handle your API
              team&apos;s support and whole-company support, they will have
              their own space, and configuration. <br />
            </p>
          </div>
          <div className="relative md:w-1/2">
            <WomanChillingOutIllustration
              className="hidden md:block absolute z-20 h-72"
              style={{ right: "-1rem", top: "-9.1rem" }}
            />

            <div className="flex relative z-10 justify-center items-center p-6 mx-auto max-w-xl md:max-w-full bg-white rounded-lg shadow-lg">
              <Image
                alt="Screenshot of the shift schedule edit page"
                height={450}
                placeholder="blur"
                quality={70}
                src={createShiftPic}
                width={600}
              />
            </div>
            <div className="absolute inset-0 mx-auto md:mx-0 max-w-xl md:max-w-full bg-indigo-100 rounded-lg transform rotate-1 translate-x-4 -translate-y-3"></div>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-20 md:mt-44 max-w-7xl">
        <div className="md:flex md:flex-row-reverse items-center px-6 mx-auto">
          <div className="pb-8 md:pl-24 md:w-1/2">
            <h2 className="mx-auto max-w-lg md:max-w-none text-2xl sm:text-4xl font-extrabold tracking-tight leading-8 sm:leading-tight text-gray-900">
              Assign Teams and Slack Users
            </h2>
            <p className="mx-auto mt-3 max-w-lg md:max-w-none text-lg leading-7 text-gray-600">
              TurnShift stays in sync with your Slack user lists so you can
              easily create teams and add users to them.
            </p>
          </div>
          <div className="relative md:w-1/2">
            <div className="flex relative z-10 justify-center items-center p-6 mx-auto max-w-xl md:max-w-full bg-white rounded-lg shadow-lg">
              <Image
                alt="Screenshot of the team members edit page"
                height={450}
                placeholder="blur"
                quality={70}
                src={manageTeamsPic}
                width={600}
              />
            </div>
            <div className="absolute inset-0 mx-auto md:mx-0 max-w-xl md:max-w-full bg-indigo-100 rounded-lg transform -rotate-1 -translate-x-4 -translate-y-3"></div>
          </div>
        </div>
      </div>

      <div className="container relative mx-auto mt-20 md:mt-72 max-w-7xl">
        <div className="md:flex items-center px-6 mx-auto">
          <div className="md:pr-24 pb-8 md:w-1/2">
            <h2 className="mx-auto max-w-lg md:max-w-none text-2xl sm:text-4xl font-extrabold tracking-tight leading-8 sm:leading-tight text-gray-900">
              Get Slack Notifications
            </h2>
            <p className="mx-auto mt-3 max-w-lg md:max-w-none text-lg leading-7 text-gray-600">
              Every day, right before a shift start, TurnShift sends Slack
              notifications to channels, direct messages to assignees, and
              updates the topic with who&apos;s on shift.
            </p>
          </div>
          <div className="relative md:w-1/2">
            <ManChillingOutIllustration
              className="hidden md:block absolute z-20 h-72"
              style={{ left: "-7rem", top: "-9.55rem" }}
            />
            <div className="flex relative z-10 justify-center items-center mx-auto max-w-xl md:max-w-full bg-white rounded-lg shadow-lg">
              <Image
                alt="Screenshot of a booking reminder Slack notification sent by the TurnShift Slack application"
                height={409}
                placeholder="blur"
                quality={70}
                src={slackNotificationsPic}
                width={616}
              />
            </div>
            <div className="absolute inset-0 mx-auto md:mx-0 max-w-xl md:max-w-full bg-indigo-100 rounded-lg transform rotate-1 translate-x-4 -translate-y-3"></div>
          </div>
        </div>
      </div>

      <div className="container relative my-20 md:my-44 mx-auto max-w-7xl">
        <div className="md:flex md:flex-row-reverse items-center px-6 mx-auto">
          <div className="pb-8 md:pl-24 md:w-1/2">
            <h2 className="mx-auto max-w-lg md:max-w-none text-2xl sm:text-4xl font-extrabold tracking-tight leading-8 sm:leading-tight text-gray-900">
              Receive Booking Reminders
            </h2>
            <p className="mx-auto mt-3 max-w-lg md:max-w-none text-lg leading-7 text-gray-600">
              When it&apos;s time to book shifts again, TurnShift will send
              Slack notifications only to the required team members.
              <br />
              <br />
              Booking should never be a struggle, that&apos;s why we use magic
              links: click on the &quot;Book&quot; button and you&apos;re
              already logged in.
            </p>
          </div>
          <div className="relative md:w-1/2">
            <div className="flex relative z-10 justify-center items-center mx-auto max-w-xl md:max-w-full bg-white rounded-lg shadow-lg">
              <Image
                alt="Screenshot of a booking reminder Slack notification sent by the TurnShift Slack application"
                height={409}
                quality={70}
                src="/home-page/booking-reminder.png"
                width={616}
              />
            </div>
            <div className="absolute inset-0 mx-auto md:mx-0 max-w-xl md:max-w-full bg-indigo-100 rounded-lg transform rotate-1 translate-x-4 -translate-y-3"></div>
          </div>
        </div>
      </div>

      <div className="pt-20 -mt-20" id="pricing"></div>

      <div className="mt-10 bg-gradient-to-b from-indigo-50 via-white to-white">
        <Pricing />
      </div>

      <div className="pt-20 -mt-20" id="faq"></div>

      <div className="bg-gray-50">
        <div className="py-12 sm:py-16 px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl">
          <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
            <h2 className="text-3xl sm:text-4xl font-extrabold text-center text-gray-900">
              Frequently asked questions
            </h2>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              <Question title="Which of our Slack data do you have access to?">
                <p>
                  First,{" "}
                  <strong>TurnShift can&apos;t read your messages</strong>.
                </p>
                <p>
                  Using the requested permissions, the TurnShift slack
                  application can:
                </p>
                <ul>
                  <li>Read your list of users</li>
                  <li>Read the list and topics of public channels</li>
                  <li>
                    Read the list and topics of private channels you added
                    TurnShift to
                  </li>
                  <li>Join public channels</li>
                  <li>Send messages to channels you added TurnShift to</li>
                  <li>Send messages to users</li>
                  <li>Change channel topics</li>
                </ul>
                <p>
                  The permissions we request from Slack are as follow:{" "}
                  <a
                    href="https://api.slack.com/scopes/chat:write"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    chat:write
                  </a>
                  ,{" "}
                  <a
                    href="https://api.slack.com/scopes/users:read"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    users:read
                  </a>
                  ,{" "}
                  <a
                    href="https://api.slack.com/scopes/users:read.email"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    users:read.email
                  </a>
                  ,{" "}
                  <a
                    href="https://api.slack.com/scopes/channels:read"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    channels:read
                  </a>
                  ,{" "}
                  <a
                    href="https://api.slack.com/scopes/channels:join"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    channels:join
                  </a>
                  ,{" "}
                  <a
                    href="https://api.slack.com/scopes/channels:manage"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    channels:manage
                  </a>
                  ,{" "}
                  <a
                    href="https://api.slack.com/scopes/team:read"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    team:read
                  </a>
                  .
                </p>
                <p>
                  When you allow TurnShift to access private channels (the
                  default configuration), we also request:{" "}
                  <a
                    href="https://api.slack.com/scopes/groups:read"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    groups:read
                  </a>
                  ,{" "}
                  <a
                    href="https://api.slack.com/scopes/groups:write"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    groups:write
                  </a>
                  .
                </p>
                <p>
                  <strong>
                    Please note that we can only access private channels you
                    added TurnShift to. We can&apos;t list all of your private
                    channels.
                  </strong>
                </p>
              </Question>
              <Question title="Which of our Slack data do you store in your database?">
                <p>
                  For TurnShift to work well we keep some of your data in sync
                  in our own database:
                </p>

                <ul>
                  <li>
                    Channels: id. We do not store channel names as they can
                    contain data from your customers (#cust-nike).
                  </li>
                  <li>Users: id, name, email, avatar, time zone.</li>
                  <li>
                    Workspace: id, name, avatar, one access token (to interact
                    with Slack API).
                  </li>
                </ul>

                <p>
                  We only store data when we really need it for application
                  purpose, that&apos;s it.
                </p>
              </Question>
              <Question title="How do you consider active users?">
                <p>
                  We charge per active users. An active user is a user part of
                  any active schedule.
                </p>
                <p>
                  If there are 300 people in your Slack workspace but only 5 of
                  them are part of a TurnShift schedule then you only pay for
                  those 5 users.
                </p>
              </Question>
              <Question title="Do you have an enterprise pricing?">
                <p>
                  Yes! Contact us at{" "}
                  <a href="mailto:support@turnshift.app">
                    support@turnshift.app
                  </a>{" "}
                  so we can discuss it.
                </p>
              </Question>
              <Question title="I have another question">
                <p>
                  Great! Use the chat here or send an email to{" "}
                  <a href="mailto:support@turnshift.app">
                    support@turnshift.app
                  </a>
                  .
                </p>
              </Question>
            </dl>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style global jsx>
        {`
          html {
            scroll-behavior: smooth;
          }
        `}
      </style>
    </>
  );
}

function Question({ title, children }: { title: string; children: ReactNode }) {
  const [isOpened, setIsOpened] = useState(false);
  const id = camelcase(title);

  return (
    <div className="pt-6">
      <dt className="text-lg">
        <button
          aria-controls={id}
          aria-expanded={isOpened ? "true" : "false"}
          className="flex justify-between items-start w-full text-left text-gray-400"
          onClick={() => {
            setIsOpened(!isOpened);
          }}
          type="button"
        >
          <span className="font-medium text-gray-900">{title}</span>
          <span className="flex items-center ml-6 h-7">
            <ChevronDownIcon
              aria-hidden="true"
              className={cx(
                "h-6 w-6 transform",
                isOpened ? "-rotate-180" : "rotate-0",
              )}
            />
          </span>
        </button>
      </dt>
      <dd
        className={cx(
          "prose prose-sm sm:prose-lg text-gray-500 mt-2 pr-12",
          isOpened ? "block" : "hidden",
        )}
        id={id}
      >
        {children}
      </dd>
    </div>
  );
}

function Pricing() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;
  const [currency, setCurrency] = useState("EUR");
  const [exchangeRates, setExchangeRates] = useState({
    USD: 1.1947502673253723,
    EUR: 1,
  });

  useEffect(() => {
    if (!timeZone) {
      setCurrency("USD");
    } else if (timeZone.startsWith("Europe")) {
      setCurrency("EUR");
    } else {
      setCurrency("USD");
    }
  }, [timeZone]);

  useEffect(() => {
    fetch("/api/exchangeRates")
      .then((res) => {
        return res.json();
      })
      .then(setExchangeRates);
  }, []);

  const currencySymbols = {
    EUR: "€",
    USD: "$",
  };

  const [billingPeriod, setBillingPeriod] = useState("yearly");

  const price =
    billingPeriod === "yearly"
      ? convert(3.5, {
          base: "EUR",
          from: "EUR",
          to: currency,
          rates: exchangeRates,
        })
      : convert(4.2, {
          from: "EUR",
          base: "EUR",
          to: currency,
          rates: exchangeRates,
        });

  const toggleCssClasses = {
    base: "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring",
    yearly: "bg-indigo-600",
    monthly: "bg-gray-200",
  };
  const toggleButtonCssClasses = {
    base: "inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200",
    yearly: "translate-x-5",
    monthly: "translate-x-0",
  };

  return (
    <div className="py-8 md:py-18 px-4 sm:px-6 md:px-8 mx-auto max-w-screen-2xl">
      <div
        className="flex justify-center items-center mt-16 space-x-8"
        style={{ scrollMarginTop: "140px" }}
      >
        <span className="text-2xl sm:text-4xl lg:text-5xl font-extrabold tracking-tight text-gray-900">
          Simple Pricing
        </span>
      </div>
      <div className="flex justify-center items-center mt-4 space-x-3">
        <span
          aria-checked="false"
          aria-labelledby="toggleLabel"
          // @ts-ignore: too complex to use types here
          className={cx(toggleCssClasses.base, toggleCssClasses[billingPeriod])}
          onClick={() => {
            billingPeriod === "monthly"
              ? setBillingPeriod("yearly")
              : setBillingPeriod("monthly");
          }}
          role="checkbox"
          tabIndex={0}
        >
          <span
            aria-hidden="true"
            className={cx(
              toggleButtonCssClasses.base,
              // @ts-ignore: too complex to use types here
              toggleButtonCssClasses[billingPeriod],
            )}
          ></span>
        </span>
        <span
          id="toggleLabel"
          onClick={() => {
            billingPeriod === "monthly"
              ? setBillingPeriod("yearly")
              : setBillingPeriod("monthly");
          }}
        >
          <span className="text-lg font-medium text-gray-900 cursor-pointer">
            Annual billing{" "}
          </span>
          <span className="text-lg text-gray-500">(2 months free)</span>
        </span>
      </div>
      <div className="md:flex md:justify-between md:items-start pb-10 mt-16 sm:mt-32 text-center md:text-left">
        <div>
          <h1 className="text-3xl sm:text-4xl font-extrabold sm:tracking-tight leading-10 sm:leading-none text-center md:text-left">
            <span className="text-gray-900">Start Scheduling Shifts for </span>
            <span className="text-indigo-600">
              <br className="block md:hidden" />
              {/* @ts-ignore: too complex to use types here */}
              {currencySymbols[currency]}
              <span
                style={{
                  fontFeatureSettings: "tnum",
                  fontVariantNumeric: "tabular-nums",
                }}
              >
                {price.toFixed(2)}
              </span>{" "}
              / user / month
            </span>
          </h1>
          <p className="mt-5 text-lg leading-7 text-center md:text-left text-gray-600">
            Includes every current and upcoming feature. We charge for active
            users only. <br />
            <strong>Need enterprise pricing?</strong>{" "}
            <a
              className="underline"
              href="mailto:support@turnshift.app?subject=Enterprise Pricing"
            >
              Contact-us
            </a>
            .
          </p>
        </div>
        <a // eslint-disable-line @next/next/no-html-link-for-pages
          className="inline-flex justify-center items-center py-3 px-5 mt-8 sm:mt-10 xl:mt-0 w-full sm:w-auto text-base leading-6 text-white hover:text-gray-900 bg-indigo-600 hover:bg-white rounded-md border focus:ring shadow transition duration-150 ease-in-out focus:outline-none"
          href="/api/slack/directInstallRedirect?fromHomePage=yes"
        >
          <div className="p-2 mr-2 bg-white rounded-full">
            <SlackLogoMark className="w-5 h-5" />
          </div>{" "}
          14-day free trial
        </a>
      </div>
      <div className="xl:grid xl:grid-cols-3 xl:gap-x-8 pt-16 mx-auto max-w-lg md:max-w-none border-t border-gray-200">
        <div>
          <h2 className="text-base font-semibold tracking-wide leading-6 text-indigo-600 uppercase">
            Everything you need
          </h2>
        </div>
        <div className="md:grid md:grid-cols-2 xl:col-span-2 md:gap-x-8 mt-4 sm:mt-8 md:mt-10 xl:mt-0">
          <ul className="divide-y divide-gray-200">
            <li className="flex py-4 md:py-0 md:pb-4 space-x-3">
              <CheckIcon className="w-6 h-6 text-green-500" />
              <span className="text-base leading-6 text-gray-600">
                Unlimited shift schedules
              </span>
            </li>
            <li className="flex py-4 space-x-3">
              <CheckIcon className="w-6 h-6 text-green-500" />
              <span className="text-base leading-6 text-gray-600">
                Unlimited teams
              </span>
            </li>
            <li className="flex py-4 space-x-3">
              <CheckIcon className="w-6 h-6 text-green-500" />
              <span className="text-base leading-6 text-gray-600">
                Slack synchronization
              </span>
            </li>
            <li className="flex py-4 space-x-3">
              <CheckIcon className="w-6 h-6 text-green-500" />
              <span className="text-base leading-6 text-gray-600">
                Smart notifications
              </span>
            </li>
          </ul>
          <ul className="border-t md:border-t-0 border-gray-200 divide-y divide-gray-200">
            <li className="flex py-4 md:py-0 md:pb-4 space-x-3 md:border-t-0">
              <CheckIcon className="w-6 h-6 text-green-500" />
              <span className="text-base leading-6 text-gray-600">
                Time Zone support
              </span>
            </li>
            <li className="flex py-4 space-x-3">
              <BeakerIcon className="w-6 h-6 text-gray-500" />
              <span className="text-base leading-6 text-gray-600">
                Calendar sync (soon)
              </span>
            </li>
            <li className="flex py-4 space-x-3">
              <BeakerIcon className="w-6 h-6 text-gray-500" />
              <span className="text-base leading-6 text-gray-600">
                Holiday detection (soon)
              </span>
            </li>
            <li className="flex py-4 space-x-3">
              <BeakerIcon className="w-6 h-6 text-gray-500" />
              <span className="text-base leading-6 text-gray-600">
                Auto-booking (soon)
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export default HomePage;
