import Image from "next/image";

import AlgoliaLogo from "svg/algolia.svg";
import MobileClubLogo from "svg/mobile-club.svg";

function QuoteIcon(): JSX.Element {
  return (
    <svg
      aria-hidden="true"
      className="absolute top-0 left-0 w-8 h-8 text-gray-200 transform -translate-x-3 -translate-y-2"
      fill="currentColor"
      viewBox="0 0 32 32"
    >
      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
    </svg>
  );
}

export default function Testimonials(): JSX.Element {
  return (
    <section>
      <div className="md:grid md:grid-cols-2 md:px-6 lg:px-8 mx-auto max-w-7xl">
        <div className="md:flex md:flex-col py-12 md:py-16 px-4 sm:px-6 md:pr-10 lg:pr-16 md:pl-0 md:border-r md:border-gray-400">
          <div className="md:flex-shrink-0">
            <a
              className="inline-flex"
              href="https://algolia.com"
              rel="noreferrer"
              target="_blank"
            >
              <AlgoliaLogo className="w-auto h-10" />
            </a>
          </div>
          <blockquote className="md:flex md:flex-col md:flex-grow mt-6">
            <div className="relative md:flex-grow text-lg text-gray-600">
              <QuoteIcon />
              <p className="relative text-justify">
                We use TurnShift to share Algolia&apos;s customer support duty
                with 50+ engineers. Previously we were using an internal tool
                but nobody wanted to maintain it on top of their work.
                <br />
                <br />
                TurnShift ensures our support schedule is fully booked, up to
                date, and remind people to show up on time.
                <br />
                <br />
                Switching to this tool was a relief, and now multiple teams are
                using it: win!
              </p>
            </div>
            <footer className="mt-8 font-medium">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 rounded-full border-2 border-white">
                  <a
                    className="overflow-hidden w-16 h-16 rounded-full"
                    href="https://www.linkedin.com/in/maria-elisabeth-schreiber/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Image
                      alt="Maria Schreiber"
                      height={200}
                      quality={95}
                      src="/maria.png"
                      width={200}
                    />
                  </a>
                </div>
                <div className="ml-4">
                  <div className="text-lg font-medium text-gray-900">
                    <a
                      href="https://www.linkedin.com/in/maria-elisabeth-schreiber/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Maria Schreiber
                    </a>
                  </div>
                  <div className="text-lg font-medium text-gray-600">
                    Head of Support,{" "}
                    <a
                      className="underline"
                      href="https://algolia.com"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Algolia
                    </a>
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
        <div className="py-12 md:py-16 px-4 sm:px-6 md:pr-0 md:pl-10 lg:pl-16 border-t-2 md:border-t-0 md:border-l border-gray-400">
          <div className="md:flex-shrink-0">
            <a
              className="inline-flex"
              href="https://mobile.club"
              rel="noreferrer"
              target="_blank"
            >
              <MobileClubLogo className="h-10" />
            </a>
          </div>
          <blockquote className="md:flex md:flex-col md:flex-grow mt-6">
            <div className="relative md:flex-grow text-lg text-gray-600">
              <QuoteIcon />
              <p className="relative text-justify">
                Our engineering team provides internal support. We started with
                a shared Google Calendar. It was super easy to setup but
                required a lot of manual actions to keep it up to date.
                <br />
                <br />
                We also wanted Slack notifications, so we tried TurnShift and
                haven&apos;t looked back since then.
                <br />
                <br />
                We even cheer up folks on duty when they get mentioned by the
                TurnShift bot :)
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 rounded-full border-2 border-white">
                  <a
                    className="overflow-hidden w-16 h-16 rounded-full"
                    href="https://www.linkedin.com/in/maxime-janton-33936151"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Image
                      alt="Maxime Janton"
                      height={200}
                      quality={95}
                      src="/maxime.png"
                      width={200}
                    />
                  </a>
                </div>
                <div className="ml-4">
                  <div className="text-lg font-medium text-gray-900">
                    <a
                      href="https://www.linkedin.com/in/maxime-janton-33936151"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Maxime Janton
                    </a>
                  </div>
                  <div className="text-lg font-medium text-gray-600">
                    Lead Software Engineer,{" "}
                    <a
                      className="underline"
                      href="https://mobile.club"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Mobile Club
                    </a>
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
}
